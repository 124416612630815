import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import {
  Employee,
  employeeActions,
} from "../../../core/store/slices/employeeSlices";
import { Link } from "react-router-dom";
import * as fa from "react-icons/fa";
import { fetchRandomUser } from "../../../core/APICalls";
import EmployeeCard from "../../../components/EmployeeCard/EmployeeCard";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import Utils from "../../../core/utils/Utils";


/**
 * EmployeeList is a React functional component that displays a list of employees
 * It allows users to filter employees based on a search query and sort them by various attributes such as name, title, department, or location
 * The component also supports pagination to handle large datasets efficiently
 * Sorting preferences are saved and restored using session storage to maintain state across page reloads
 * It utilizes Redux for state management, fetching employee data from a global store and dispatching actions to update it
 * Additionally, it provides a clear all button which clears all employees from the store after a confirmation dialog
 */
const EmployeeList: React.FC = () => {
  const employees = useSelector((state: RootState) => state.employee.employees);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState<keyof Employee | "">("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [isClearDialogOpen, setIsClearDialogOpen] = useState(false);

  const employeesPerPage = 10;

  useEffect(() => {
    // Restore sort state from session storage
    const savedSortKey = sessionStorage.getItem("sortKey");
    const savedSortOrder = sessionStorage.getItem("sortOrder");

    if (savedSortKey) {
      setSortKey(savedSortKey as keyof Employee);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as "asc" | "desc");
    }
  }, []);

  useEffect(() => {
    // Save sort state to session storage
    sessionStorage.setItem("sortKey", sortKey);
    sessionStorage.setItem("sortOrder", sortOrder);
  }, [sortKey, sortOrder]);

  const filteredEmployees = employees.filter(
    (emp) =>
      emp.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      emp.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      emp.department.toLowerCase().includes(searchQuery.toLowerCase()) ||
      emp.location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedEmployees = [...filteredEmployees].sort((a, b) => {
    if (!sortKey) return 0;
    const aValue = a[sortKey] as string;
    const bValue = b[sortKey] as string;

    if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const paginatedEmployees = sortedEmployees.slice(
    (currentPage - 1) * employeesPerPage,
    currentPage * employeesPerPage
  );

  const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);

  const handleSort = (key: keyof Employee) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const getSortArrowClass = (key: keyof Employee, order: "asc" | "desc") => {
    return sortKey === key && sortOrder === order ? "active" : "";
  };

  const clearEmployees = () => {
    setIsClearDialogOpen(true);
  };

  
  const handleCloseDialog = (shouldClear: boolean) => {
    setIsClearDialogOpen(false);
    if (shouldClear) {
      dispatch(employeeActions.clearEmployees());
    }
  };

  const addDummyEmployee = () => {
    fetchRandomUser({ results: 1 })
      .then((response) => {
        if (response.results.length !== 1) return;

        let employee: Employee = {
          id: employees.length.toString(),
          name: `${response.results[0].name.first} ${response.results[0].name.last}`,
          title: response.results[0].email,
          department: response.results[0].location.city,
          location: `${response.results[0].location.city}, ${response.results[0].location.country}`,
          picture: response.results[0].picture.thumbnail,
        };

        dispatch(employeeActions.addEmployee(employee));
      })
      .catch((error) => console.error("Failed to fetch users", error));
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center my-4">
        <div className="flex items-center gap-4">
          <h1 className="text-3xl font-bold">Employee List</h1>
          <Link
            to="/employee/new"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            New
          </Link>
          <button
            onClick={addDummyEmployee}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Dummy
          </button>
          <button
            onClick={clearEmployees}
            className="text-red-500 px-2 py-2 text-sm"
          >
            Clear All
          </button>
        </div>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border p-2 rounded"
        />
      </div>
      <div className="table-wrapper w-full">
        <table className="table-fixed w-full border-collapse">
          <thead>
            <tr>
              {["name", "title", "department", "location"].map((key) => (
                <th
                  key={key}
                  onClick={() => handleSort(key as keyof Employee)}
                  className="sortable-header px-4 py-2"
                >
                  <div className="header-content flex justify-between items-center">
                    <span>{Utils.capitalize(key)}</span>
                    <div className="sort-icons flex flex-col">
                      <fa.FaSortUp
                        className={getSortArrowClass(
                          key as keyof Employee,
                          "asc"
                        )}
                      />
                      <fa.FaSortDown
                        className={getSortArrowClass(
                          key as keyof Employee,
                          "desc"
                        )}
                      />
                    </div>
                  </div>
                </th>
              ))}
              <th className="sortable-header px-4 py-2">
                <div className="header-content">
                  {/* <span>Actions</span> */}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedEmployees.map((emp) => (
              <EmployeeCard key={emp.id} employee={emp} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center gap-2">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-4 py-2 rounded ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <Dialog
        open={isClearDialogOpen}
        onClose={() => handleCloseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Clear All Employees?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to clear all employees?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialog(false)}
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleCloseDialog(true)}
            color="primary"
            autoFocus
            sx={{
              textTransform: "none",
              backgroundColor: "red",
              color: "white",
              "&:hover": { backgroundColor: "darkred" },
              borderRadius: "8px",
            }}
          >
            Clear All
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeeList;
