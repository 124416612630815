import React, { useEffect } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home/Home";
import EmployeeList from "./pages/Employee/List/EmployeeList";
import NewEmployeeForm from "./pages/Employee/New/NewEmployeeForm";
import { fetchRandomUser } from "./core/APICalls";
import { Employee, employeeActions } from "./core/store/slices/employeeSlices";
import { User } from "./core/models/User";
import { AppDispatch } from "./core/store/store";
import { useDispatch } from "react-redux";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "employees", element: <EmployeeList /> },
      { path: "employee/:id", element: <NewEmployeeForm /> },
      { path: "employee/new", element: <NewEmployeeForm /> },
    ],
  },
]);

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    // Fetching data from the API, page should be used later on to fetch data inside EmployeeList.tsx for pagination
    fetchRandomUser({ page: 1, results: 100 })
      .then((response) => {
        console.log(response);
        let employees: Employee[] = response.results.map(
          (user: User, index: number) => ({
            id: index.toString(),
            name: `${user.name.first} ${user.name.last}`,
            title: user.name.title,
            department: user.location.city,
            location: user.location.country,
            picture: user.picture.thumbnail,
          })
        );
        dispatch(employeeActions.setEmployees(employees));
      })
      .catch((error) => console.error("Failed to fetch users", error));
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
