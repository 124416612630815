// NewEmployeeForm.tsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { employeeActions } from "../../../core/store/slices/employeeSlices";
import { RootState } from "../../../core/store/store";
import { v4 as uuidv4 } from "uuid";

const NewEmployeeForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const existingEmployee = useSelector((state: RootState) =>
    state.employee.employees.find((emp) => emp.id === id)
  );

  const [employee, setEmployee] = useState({
    name: "",
    title: "",
    location: "",
    department: "",
    picture: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    title: "",
    location: "",
    department: "",
    picture: "",
  });

  useEffect(() => {
    if (existingEmployee) {
      setEmployee(existingEmployee);
    }
  }, [existingEmployee]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmployee({
      ...employee,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newErrors = {
      name: "",
      title: "",
      location: "",
      department: "",
      picture: "",
    };

    if (!employee.name.trim()) newErrors.name = "Name is required";
    if (!employee.title.trim()) newErrors.title = "Title is required";
    if (!employee.location.trim()) newErrors.location = "Location is required";
    if (!employee.department.trim())
      newErrors.department = "Department is required";
    if (!employee.picture.trim()) newErrors.picture = "Picture URL is required";

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;

    if (id) {
      // Update existing employee
      dispatch(employeeActions.updateEmployee({ ...employee, id }));
    } else {
      // Add new employee
      const newEmployee = { ...employee, id: uuidv4() };
      dispatch(employeeActions.addEmployee(newEmployee));
    }

    navigate("/employees");
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold">
        {id ? "Update Employee" : "Add Employee"}
      </h1>
      <form
        onSubmit={handleSubmit}
        className="shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={employee.name}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.name ? "border-red-500" : ""
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-xs italic">{errors.name}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="title"
          >
            Title
          </label>
          <input
            id="title"
            name="title"
            type="text"
            value={employee.title}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.title ? "border-red-500" : ""
            }`}
          />
          {errors.title && (
            <p className="text-red-500 text-xs italic">{errors.title}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="location"
          >
            Location
          </label>
          <input
            id="location"
            name="location"
            type="text"
            value={employee.location}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.location ? "border-red-500" : ""
            }`}
          />
          {errors.location && (
            <p className="text-red-500 text-xs italic">{errors.location}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="department"
          >
            Department
          </label>
          <input
            id="department"
            name="department"
            type="text"
            value={employee.department}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.department ? "border-red-500" : ""
            }`}
          />
          {errors.department && (
            <p className="text-red-500 text-xs italic">{errors.department}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="picture"
          >
            Picture URL
          </label>
          <input
            id="picture"
            name="picture"
            type="text"
            value={employee.picture}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.picture ? "border-red-500" : ""
            }`}
          />
          {errors.picture && (
            <p className="text-red-500 text-xs italic">{errors.picture}</p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {id ? "Update Employee" : "Add Employee"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewEmployeeForm;
