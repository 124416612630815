import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Employee {
    id: string;
    name: string;
    title: string;
    location: string;
    department: string;
    picture: string;
}

interface EmployeeState {
    employees: Employee[];
}

const initialState: EmployeeState = {
    employees: [],
};

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployees: (state, action: PayloadAction<Employee[]>) => {
            console.log("Setting employees in the store: ", action.payload);
            state.employees = action.payload;
        },
        addEmployee: (state, action: PayloadAction<Employee>) => {
            state.employees.push(action.payload);
        },
        updateEmployee: (state, action: PayloadAction<Employee>) => {
            const index = state.employees.findIndex(emp => emp.id === action.payload.id);
            if (index >= 0) {
                state.employees[index] = action.payload;
            }
        },
        deleteEmployee: (state, action: PayloadAction<string>) => {
            state.employees = state.employees.filter(emp => emp.id !== action.payload);
        },
        clearEmployees: (state) => {
            state.employees = [];
        },
    },
});

// export const { addEmployee, updateEmployee, deleteEmployee } = employeeSlice.actions;
export const employeeActions = employeeSlice.actions;

export default employeeSlice.reducer;
