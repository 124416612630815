// src/utils/ApiService.ts

// Utility function to serialize query parameters
const serializeParams = (params: Record<string, any>): string => {
    return new URLSearchParams(params).toString();
  };
  
  interface RequestOptions {
    params?: Record<string, any>;
    headers?: Record<string, string>;
  }
  
  export default class ApiService {
    static async get<T>(url: string, options: RequestOptions = {}): Promise<T> {
      const { params, headers } = options;
  
      // Construct the full URL with query parameters
      const fullUrl = params ? `${url}?${serializeParams(params)}` : url;
  
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: headers || {}
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch from ${fullUrl}: ${response.statusText}`);
      }
  
      return response.json() as Promise<T>;
    }
  
    static async post<T, U>(url: string, data: U, options: RequestOptions = {}): Promise<T> {
      const { headers } = options;
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`Failed to post to ${url}: ${response.statusText}`);
      }
  
      return response.json() as Promise<T>;
    }
  
    static async put<T, U>(url: string, data: U, options: RequestOptions = {}): Promise<T> {
      const { headers } = options;
  
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`Failed to put to ${url}: ${response.statusText}`);
      }
  
      return response.json() as Promise<T>;
    }
  
    static async delete<T>(url: string, options: RequestOptions = {}): Promise<T> {
      const { params, headers } = options;
  
      // Construct the full URL with query parameters
      const fullUrl = params ? `${url}?${serializeParams(params)}` : url;
  
      const response = await fetch(fullUrl, {
        method: 'DELETE',
        headers: headers || {}
      });
  
      if (!response.ok) {
        throw new Error(`Failed to delete from ${fullUrl}: ${response.statusText}`);
      }
  
      return response.json() as Promise<T>;
    }
  }
  