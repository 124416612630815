// src/APICalls.ts
// src/api/apiManager.ts
import ApiService from '../core/api/APIService';
import { ApiResponse } from '../core/models/User';

export const fetchRandomUser = ({
  page,
  results,
}: {
  page?: number;
  results?: number;
} = {}): Promise<ApiResponse> => {
  const params: Record<string, any> = {};

  if (page !== undefined) params.page = page;
  if (results !== undefined) params.results = results;

  return ApiService.get<ApiResponse>('https://randomuser.me/api', { params });
};
