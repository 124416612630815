// Home.tsx
import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/Nav/NavBar";

/**
 * Renders the Home component
 * 
 * Home component displays a welcome message and a button to view the employees
 */
const Home: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Welcome to the Employee Directory
        </h1>
        <p className="text-gray-600 mb-6">
          Manage and view your company's employees efficiently.
        </p>
        <Link
          to="/employees"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          View Employees
        </Link>
      </div>
    </div>
  );
};

export default Home;
