import React from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * NavBar is a React functional component that renders a navigation bar for the application
 * It uses React Router's `useLocation` hook to determine the current route and visually indicates the active link by underlining it
 * The navigation bar includes links to the Home page and the Employees page.
 */
const NavBar: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="bg-blue-500 py-4">
      <div className="container mx-auto flex justify-center">
        <Link 
          to="/" 
          className={`mx-4 text-white font-bold ${location.pathname === '/' ? 'underline' : ''}`}
        >
          Home
        </Link>
        <Link 
          to="/employees" 
          className={`mx-4 text-white font-bold ${location.pathname === '/employees' ? 'underline' : ''}`}
        >
          Employees
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;