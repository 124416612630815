import React, { useState } from "react";
import { Employee } from "../../core/store/slices/employeeSlices";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { employeeActions } from "../../core/store/slices/employeeSlices";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { CSSTransition } from "react-transition-group";

interface EmployeeCardProps {
  employee: Employee;
}

/**
 * EmployeeCard is a React functional component that displays detailed information about an employee
 * It presents the employee's name, title, department, department, and location
 * Is is typically used within a list layout
 */
const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee }) => {
  const dispatch = useDispatch();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleDelete = () => {
    setIsVisible(false);
    setTimeout(
      () => dispatch(employeeActions.deleteEmployee(employee.id)),
      300
    );
  };

  const openRemoveDialog = () => {
    setIsRemoveDialogOpen(true);
  };

  const closeDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  return (
    <>
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <tr>
          <td colSpan={5} className="py-2">
            <div className="border border-gray-200 hover:bg-gray-100 rounded-lg shadow my-2 dark:hover:bg-gray-800 transition-colors">
              <div className="grid grid-cols-5 gap-4 p-4">
                <div className="flex items-center col-span-1">
                  <img
                    src={employee.picture}
                    alt={employee.name}
                    className="w-12 h-12 rounded-full mr-4 shadow-lg"
                  />
                  <span>{employee.name}</span>
                </div>
                <div className="col-span-1 px-4 py-2">{employee.title}</div>
                <div className="col-span-1 px-4 py-2">
                  {employee.department}
                </div>
                <div className="col-span-1 px-4 py-2">{employee.location}</div>
                <div className="col-span-1 flex items-center justify-center gap-4">
                  <Link
                    to={`/employee/${employee.id}`}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FaEdit size={20} />
                  </Link>
                  <button
                    onClick={openRemoveDialog}
                    className="text-red-500 hover:text-red-700 ml-4"
                  >
                    <FaTrash size={20} />
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </CSSTransition>

      <Dialog
        open={isRemoveDialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title">{"Delete Employee?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {employee.name}? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            autoFocus
            sx={{
              textTransform: "none",
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "darkred",
              },
              borderRadius: "8px",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeCard;
